import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Autocomplete from "@mui/material/Autocomplete";
import { Controller } from "react-hook-form";

export function FormAutoCompleteDropdownField(props, { onChange: ignored }) {
  const {
    control,
    metaData: {
      selectedValue,
      dropdownWithIdValue,
      labelController,
      dropdownValues,
      fieldName,
      isRequired,
      isFullWidth,
      label,
      dataTestId
    },
    marginBottom,
    id,
    topMargin,
    validationErrors,
  } = props;

  return (
    <Controller
      rules={{ required: isRequired }}
      control={control}
      name={fieldName}
      render={({ field: { error, onChange, value } }) => (
        <>
          <MDBox mt={topMargin || 2}>
            <Autocomplete
              data-testid={dataTestId}
              onChange={(event, value) => {
                const valueToAssign = dropdownWithIdValue ? value.id : value;
                onChange(valueToAssign);
              }}
              defaultValue={selectedValue || null}
              value={value}
              getOptionLabel={
                dropdownWithIdValue
                  ? (option) => {
                      const arrLblController = labelController.split(" ");
                      if (arrLblController.length === 1) {
                        return option[labelController];
                      }
                      return `${option[arrLblController[0]]} ${option[arrLblController[1]]}`;
                    }
                  : (option) => option
              }
              getOptionSelected={(option, value) =>
                value === undefined || value === "" || option.id === value.id
              }
              disablePortal
              options={dropdownValues}
              id={id}
              sx={{
                marginBottom: marginBottom || 2,
              }}
              fullWidth={isFullWidth}
              renderInput={(params) => (
                <MDInput              
                {...params} label={label} error={validationErrors[fieldName]} />
              )}
            />
          </MDBox>
          {validationErrors[fieldName]?.type === "required" && (
            <MDTypography variant="caption" color="error">
              Bu alan zorunludur.
            </MDTypography>
          )}
        </>
      )}
    />
  );
}
