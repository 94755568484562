import { Fragment, React, useRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import LoaderSpinner from "components/CustomComponents/Loader/LoaderSpinner";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Link } from "react-router-dom";

import { callAPIGetDocumentTypes } from "apiCalls/documentTypeApiCalls";
import { callAPIAddDocument } from "apiCalls/documentApiCalls";
import { createDocument } from "pages/Common/documentActions";
import SuccessMessage from "../../../components/CustomComponents/Notifications/SuccessMessage";
import ErrorMessage from "../../../components/CustomComponents/Notifications/ErrorMessage";
import {
  callAPICreateNewVehicle,
  callAPIGetVehicleDetails,
  callAPIUpdateVehicle,
} from "../../../apiCalls/vehicleApiCalls";
import { inputFieldsMeta } from "./inputFieldsMeta.js";
import Form from "../../../components/CustomComponents/CreateEditForms/Form";

function CreateEditVehicle() {
  const url = window.location.href;
  const urlParams = new URL(url);
  const mode = urlParams.searchParams.get("mode");
  const type = urlParams.searchParams.get("type");
  const entity = urlParams.searchParams.get("entity");

  const formRef = useRef();

  const [inputFieldsMetaData, setInputFieldsMetaData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  let existingVehicleDetails = {};

  const setExistingValuesForForm = async () => {
    for (const [key, value] of Object.entries(existingVehicleDetails)) {
      // If mode is copy , do not set arr date dept date id and contract no
      if (!(mode === "copy" && key == "id")) {
        if (value !== null || value !== "" || key === "createdAt" || key === "updatedAt") {
          formRef.current.setValue(key, value);
        }
      }
    }
  };

  let documentTypes = [];

  useEffect(async () => {
    documentTypes = await getDocumentTypes();
    setInputFieldsMetaData(inputFieldsMeta({ documentTypes }));

    if (mode === "edit" || mode === "copy") {
      existingVehicleDetails = await getVehicleDetails();
      await setExistingValuesForForm();
    }

    setIsLoading(false);
  }, []);

  const getDocumentTypes = async () => {
    setIsLoading(true);
    try {
      const documentTypeResponse = await callAPIGetDocumentTypes({ entity });
      if (documentTypeResponse.status === 200) {
        setIsError(false);
        return documentTypeResponse.data.data;
      }

      setIsSuccess(false);
      setIsError(true);
      setIsLoading(false);
      setErrorMessage(
        "Dokuman cesitleri yüklenirken bir hata oluştu. Hata detayı: ",
        result.data.message
      );
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Dokuman cesitleri yüklenirken bir hata oluştu.");
    }
  };

  const getVehicleDetails = async () => {
    const id = urlParams.searchParams.get("vehicleId");
    setIsLoading(true);
    try {
      const vehicleDetailsResponse = await callAPIGetVehicleDetails({ vehicleId: id });
      if (vehicleDetailsResponse.status === 200) {
        setIsError(false);
        return vehicleDetailsResponse.data.data;
      }

      setIsSuccess(false);
      setIsError(true);
      setIsLoading(false);
      setErrorMessage(
        "Araç detaylari yüklenirken bir hata oluştu. Hata detayı: ",
        vehicleDetailsResponse.data.message
      );
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Araç detaylari yüklenirken bir hata oluştu.");
    }
  };

  const handleSave = async (data) => {
    setIsLoading(true);

    const vehicleData = {
      ...data,
    };

    if (mode === "edit") {
      updateVehicle(vehicleData);
    } else {
      createNewVehicle(vehicleData);
    }
  };

  const updateVehicle = async (data) => {
    try {
      const resultUpdatedVehicle = await callAPIUpdateVehicle(data);
      if (resultUpdatedVehicle.status === 200) {
        setIsLoading(false);
        setIsSuccess(true);
        setIsError(false);
        setSuccessMessage(`${data.plateNumber} plakali Araç başarıyla güncellendi`);
      } else {
        setIsSuccess(false);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage("Araç güncellenirken bir hata oluştu. Hata detayı: ", result.data.message);
      }
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Araç güncellenirken bir hata oluştu.");
    }
  };

  const createNewVehicle = async (data) => {
    // spread document file and data
    let { documents, ...newVehicleData } = data;
    if (mode !== "copy") newVehicleData.type = type;
    // TODO: investigate why default values don't work
    if (data.year === "") newVehicleData = { ...newVehicleData, year: null };

    newVehicleData.entity = entity;
    try {
      const resultCreateNewVehicle = await callAPICreateNewVehicle(newVehicleData);
      if (resultCreateNewVehicle.status === 200) {
        setIsLoading(false);
        setIsSuccess(true);
        setIsError(false);
        setSuccessMessage(`${newVehicleData.plateNumber} plakali araç başarıyla oluşturuldu.`);
        if (documents?.length > 0) {
          const documentDetails = {
            documents,
            entity,
            entityId: resultCreateNewVehicle.data.data.id,
          };
          await createDocument(documentDetails);
          formRef.current.resetForm();
        }
      } else {
        setIsSuccess(false);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage("Araç oluşturulurken bir hata oluştu. Hata detayı: ", result.data.message);
      }
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Araç oluşturulurken bir hata oluştu.");
    }
  };
  // TODO: Add vehicle details screen
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {inputFieldsMetaData || !isLoading ? (
        <Form
          ref={formRef}
          mode={mode}
          handleSave={handleSave}
          isLoading={isLoading}
          title={`Araç ${mode === "edit" ? "Güncelle" : "Oluştur"}`}
          inputFields={inputFieldsMetaData}
          data-testid="vehicle-form"
        />
      ) : (
        <LoaderSpinner />
      )}
      <ErrorMessage close={() => setIsError(false)} errorMessage={errorMessage} show={isError} />
      <Link to="/vehicle-details?5">
        <SuccessMessage
          close={() => setIsSuccess(false)}
          successMessage={successMessage}
          show={isSuccess}
        />
      </Link>
      <Footer />
    </DashboardLayout>
  );
}

export default CreateEditVehicle;
